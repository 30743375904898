<template>
<section class="section">
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-half-desktop">
                <h1 class="title">Numuru ģenerātors:</h1>
                <div class="mt-6">
                <form>

                    <b-field label="Gads:">
                        <b-select size="is-medium" v-model="year">
                            <option v-for="y in years" :value="y" :key="y">{{y}}</option>
                        </b-select>
                    </b-field>

                    <b-field label="Sākt ar numuru:">
                        <b-numberinput size="is-medium" v-model="start" min="1" max="99"></b-numberinput>
                    </b-field>

                    <p class="is-size-6 mb-4 mt-5 has-text-info">Atlikušie numuri: {{maxCount}}</p>

                    <b-field label="Cik numuru vajag:">
                        <b-numberinput size="is-medium" v-model="count" min="1" :max="maxCount"></b-numberinput>
                    </b-field>

                    <div class="mt-6">
                        <b-button type="is-primary" expanded :disabled="!formValid" @click="generate()" size="is-medium">Ģenerēt</b-button>
                    </div>

                </form>
                </div>
                <div v-if="results.length" class="mt-6">
                    <p v-for="r in results" :key="r" class="is-size-4">{{r}}</p>
                    <b-button type="is-ghost" @click="results = []" class="mt-5 has-text-danger">Attīrīt</b-button>
                    <b-button type="is-ghost" @click="copy()" class="mt-5">Kopēt</b-button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'Numuri',
    data () {
        return {
            year: false,
            years: [],
            start: 1,
            count: 1,
            results: [],
        }
    },
    computed: {
        maxCount () {
            return (99 - this.start + 1);
        },
        formValid () {
            if (this.start < 1 || this.start > 99) return false;
            if (this.count < 1 || this.count > this.maxCount) return false;
            return true;
        },
    },
    methods: {
        init () {
            let dt = new Date();

            this.year = dt.getFullYear();
            this.years = [
                dt.getFullYear(),
                dt.getFullYear()+1,
                dt.getFullYear()+2,
            ];
        },
        generate () {
            this.results = [];
            for (let i = 0; i < this.count; i++) {
                let item = '';
                item += String(this.year).slice(2, 4);
                item += ('0'+ String(this.start + i)).slice(-2);
                let sum = (+item[0])+(+item[1])+(+item[2])+(+item[3]);
                console.log('sum: ', sum);
                item += String(sum).slice(-1);
                this.results.push(item);
            }
        },
        copy () {
            if (navigator.clipboard) {
                let t = '';
                this.results.forEach(i => {
                    t += `${i}\n`;
                });
                navigator.clipboard.writeText(t)
                    .then(() => {
                        this.$buefy.snackbar.open({
                            message: 'Nokopēts',
                            type: 'is-success',
                            position: 'is-bottom',
                        });
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            message: 'Neizdevās nokopēt',
                            type: 'is-danger',
                            position: 'is-bottom',
                        });
                    });
            } else {
                this.$buefy.snackbar.open({
                    message: 'Nevar nokopēt',
                    type: 'is-danger',
                    position: 'is-bottom',
                });
            }
        },
    },
    mounted () {
        this.init();
    },
}
</script>

<style scoped>
</style>
