<template>
<div id="app">
    <Numuri />
</div>
</template>

<script>
import Numuri from './components/Numuri.vue'

export default {
    name: 'App',
    components: {
        Numuri
    }
}
</script>

<style>
</style>
